import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionOptionsMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.experience) {
    return null;
  }

  const experience = publicData && publicData.experience ? publicData.experience : {};

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.actingTitle" />
      </h2>
      <div className={css.option}>
        <span>{experience.isActingExperience ? 'Yes' : 'No'}</span>
      </div>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.bartendingTitle" />
      </h2>
      <div className={css.option}>
        <span>{experience.isBartendingExperience ? 'Yes' : 'No'}</span>
      </div>
    </div>
  );
};

export default SectionOptionsMaybe;
