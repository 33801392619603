import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { languagesOptions } from '../../marketplace-custom-config'

import css from './ListingPage.module.css';

const SectionLanguageMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.languages) {
    return null;
  }

  const selectedOptions = publicData && publicData.languages ? publicData.languages : [];
  const selectedConfigOptions = languagesOptions.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.languageTitle" />
      </h2>
      <div className={css.columns}>
      {selectedConfigOptions.map((i)=>{
        return (
        <span key={i.label}>
          {i.label}
        </span>)
      })}
      </div>
      
    </div>
  );
};

export default SectionLanguageMaybe;
