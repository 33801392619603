import React from "react";

function TwitterIcon({className}) {
  return (
    <svg
    className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path       
        d="M17 0C7.613 0 0 7.613 0 17s7.613 17 17 17 17-7.613 17-17S26.387 0 17 0zm7.762 13.255c.008.167.011.335.011.504 0 5.156-3.925 11.102-11.102 11.102-2.204 0-4.254-.646-5.98-1.753.304.036.615.054.93.054a7.83 7.83 0 004.846-1.67 3.906 3.906 0 01-3.645-2.71 3.88 3.88 0 001.762-.067 3.903 3.903 0 01-3.13-3.825v-.05a3.874 3.874 0 001.768.488 3.898 3.898 0 01-1.737-3.248c0-.715.193-1.384.529-1.961a11.078 11.078 0 008.043 4.077 3.903 3.903 0 016.65-3.558 7.828 7.828 0 002.476-.948 3.917 3.917 0 01-1.715 2.159 7.784 7.784 0 002.24-.615 7.93 7.93 0 01-1.946 2.02z"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
