import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { industryOptions } from '../../marketplace-custom-config'

import css from './ListingPage.module.css';

const SectionIndustryMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.languages) {
    return null;
  }

  const selectedOptions = publicData && publicData.industry ? publicData.industry : [];
  const selectedConfigOptions = industryOptions.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.industryTitle" />
      </h2>
      <div className={css.columns}>
      {selectedConfigOptions.map((i)=>{
        return (
        <span key={i.label}>
          {i.label}
        </span>)
      })}
      </div>
      
    </div>
  );
};

export default SectionIndustryMaybe;
