import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionExperienceMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  const experience = publicData && publicData.experience ? publicData.experience : {};

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.experienceTitle" />
      </h2>
      <div className={css.columns}>
      <span>
      {experience.experienceNumber}
      </span>
      </div>
      
    </div>
  );
};

export default SectionExperienceMaybe;
