import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import InstagramIcon from './icons/InstagramIcon';
import TwitterIcon from './icons/TwitterIcon';
import FacebookIcon from './icons/FacebookIcon';

import css from './ListingPage.module.css';

const SectionLanguageMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.social) {
    return null;
  }

  const social = publicData && publicData.social;
  const { instagramLink, facebookLink, twitterLink } = social || {};

  return (
    <div className={css.sectionSocial}>
      <h2 className={css.socialTitle}>
        <FormattedMessage id="ListingPage.socialTitle" />
      </h2>
      <div className={css.social}>
        {social.instagramLink && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={instagramLink}
            className={css.socialLink}
          >
            <FacebookIcon className={css.socialIcon} />
          </a>
        )}
        {social.facebookLink && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={facebookLink}
            className={css.socialLink}
          >
            <InstagramIcon className={css.socialIcon} />
          </a>
        )}
        {social.twitterLink && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={twitterLink}
            className={css.socialLink}
          >
            <TwitterIcon className={css.socialIcon} />
          </a>
        )}
      </div>
    </div>
  );
};

export default SectionLanguageMaybe;
