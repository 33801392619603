import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ReviewRating } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;
  const reviewsRating = reviews.reduce((sum,{attributes}) => {
   return sum + attributes.rating
  }, 0) / reviews.length;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return (
    <div className={css.sectionReviewsStars}>
      <ReviewRating className={css.reviewStars} reviewStarClassName={css.singleStar} rating={reviewsRating} />
      <span className={css.reviewsLabel}>
        <FormattedMessage id="ListingPage.reviewsStars" values={{ count: reviews.length }} />
      </span>
      {fetchReviewsError ? reviewsError : null}
    </div>
  );
};

export default SectionReviews;
