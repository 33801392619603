import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionLocationMaybe = props => {
  const { publicData } = props;
  if (!publicData || !publicData.languages) {
    return null;
  }

  const location = publicData && publicData.location;

  return (
    location ?
    <div className={css.sectionFeatures}>
      <h2 className={css.locationTitle}>
        <FormattedMessage id="ListingPage.locationTitle" />
      </h2>
      <div className={css.location}>
      {location?.address?.split(",")?.slice(-3)?.join(",")}
      </div>      
    </div>
    :  null  
  );
};

export default SectionLocationMaybe;
