import React from 'react';

function InstagramIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path       
        d="M34 17c0-9.39-7.61-17-17-17S0 7.61 0 17s7.61 17 17 17c.1 0 .2 0 .299-.007V20.765h-3.652V16.51h3.652v-3.135c0-3.632 2.218-5.611 5.458-5.611 1.554 0 2.89.113 3.274.166v3.798H23.8c-1.76 0-2.105.837-2.105 2.066v2.709h4.217l-.552 4.257h-3.665v12.584C28.8 31.303 34 24.763 34 17z"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
